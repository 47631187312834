import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import logoSm from "../../../assets/icons/logo-1.jpg";
import { signInFailure, signInStart, signInSuccess } from "../../../redux/user/userSlice";
import { ReadCountriesService } from "../../../services/general";
import { UserLoginService } from "../../../services/user";
import "../auth-common.css";
import Layout from "../Layout";

const UserLogin = () => {
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [countries, setCountries] = useState([]);
    const [formData, setFormData] = useState({
        mobile: "",
        password: "",
        countryCode: ""
    });
    const [formErrors, setFormErrors] = useState({
        mobile: "",
        password: "",
        countryCode: ""
    });
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "mobile" && value.startsWith("0")) {
            return;
        }
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const validatePassword = (password) => {
        const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return passwordRegex.test(password);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const countryCodeError = formData.countryCode ? "" : "Country Code cannot be Blank";
        const mobileError = formData.mobile ? "" : "Mobile cannot be Blank";
        const passwordError = validatePassword(formData.password)
            ? ""
            : "Password must be at least 8 characters long and contain letters, symbols and numbers";

        setFormErrors({
            countryCode: countryCodeError,
            mobile: mobileError,
            password: passwordError
        });

        if (!countryCodeError && !mobileError && !passwordError) {
            setLoading(true);
            dispatch(signInStart());
            let mobile = '+' + formData.countryCode + formData.mobile;
            let temp_user_login_data = await UserLoginService(mobile, formData.password);
            if (!temp_user_login_data.success) {
                dispatch(signInFailure(temp_user_login_data));
                if (temp_user_login_data.message) {
                    setFormErrors((prev) => ({ ...prev, password: temp_user_login_data.message }));
                } else {
                    setFormErrors((prev) => ({ ...prev, [temp_user_login_data.detail[0].loc[1]]: temp_user_login_data.detail[0].msg.replace("Value error, ", "") }));
                }
                setLoading(false);
                return;
            } else {
                dispatch(signInSuccess(temp_user_login_data.data.access_token));
                navigate("/dashboard");
                setLoading(false);
                return;
            }
        }
    };

    useEffect(() => {
        (async () => {
            setLoading(true);
            let temp_countries = await ReadCountriesService();
            if (temp_countries) {
                setCountries(temp_countries);
            }
            setLoading(false);
        })();
    }, []);

    return (
        <Layout>
            <div className="form-container">
                <div className="logo-wrapper">
                    <img src={logoSm} alt="" />
                </div>
                <h1 className="auth-title">Login to your account</h1>
                <form onSubmit={handleSubmit} className="auth-form signup">
                    <div className="form-group-dx">
                        <label htmlFor="mobile">Mobile Number</label>
                        <div className="form-group-country-mobile">
                            <div className="form-group-country">
                                <select id="countryCode" name="countryCode" required onChange={handleInputChange}>
                                    <option>Select Country</option>
                                    {countries.map((country) => {
                                        return (
                                            <option key={country.id} value={country.phone_code}>
                                                +{country.phone_code} - {country.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                            <div className="form-group-mobile">
                                <input
                                    type="tel"
                                    id="mobile"
                                    name="mobile"
                                    value={formData.mobile}
                                    placeholder="Enter mobile number"
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                        </div>
                        {formErrors.countryCode && <p className="error">{formErrors.countryCode}</p>}
                        {formErrors.mobile && <p className="error">{formErrors.mobile}</p>}
                    </div>
                    <div className="form-group-dx">
                        <span className="password-label-wrapper">
                            <label htmlFor="password">Password</label>
                            <button
                                type="button"
                                className="toggle-password"
                                onClick={togglePasswordVisibility}
                            >
                                {passwordVisible ? "Hide" : "Show"}
                            </button>
                        </span>
                        <div className="password-container">
                            <input
                                type={passwordVisible ? "text" : "password"}
                                id="password"
                                name="password"
                                value={formData.password}
                                placeholder="Enter password"
                                onChange={handleInputChange}
                                required
                            />
                            <div className="forgor-psw-wrapper">
                                <Link to="/forgot-password">Forgot Password?</Link>
                            </div>
                        </div>
                        <p className="password-hint">
                            Use 8 or more characters with a mix of letters, numbers & symbols
                        </p>
                    </div>
                    {formErrors.password && (
                        <p className="dx-lib input-error">{formErrors.password}</p>
                    )}
                    <button type="submit" className="submit-button dx-btn-primary" disabled={loading}>
                        {loading ? "Logging in..." : "Login"}
                    </button>
                </form>
            </div>
        </Layout>
    );
};

export default UserLogin;