import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logoSm from "../../../assets/icons/logo-1.jpg";
import { ValidateOTPResetPasswordService } from "../../../services/user";
import "../auth-common.css";

const ForgotPasswordValidate = () => {
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [formData, setFormData] = useState({
        password: "",
    });
    const [formErrors, setFormErrors] = useState({
        password: "",
    });
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    useEffect(() => {
        if (!location.state) {
            navigate('/');
        }
    }, [location, navigate]);

    const handleInputChange = (e) => {
        let { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const validatePassword = (password) => {
        const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return passwordRegex.test(password);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const passwordError = validatePassword(formData.password)
            ? ""
            : "Password must be at least 8 characters long and contain letters, symbols and numbers";

        setFormErrors({
            password: passwordError,
        });

        if (!passwordError) {
            setLoading(true);
            let temp_pass_reset = await ValidateOTPResetPasswordService(location.state.mobile, '', formData.password, false);
            if (temp_pass_reset.success) {
                navigate("/");
            } else {
                setFormErrors({
                    otp: temp_pass_reset.message,
                });
            }
            setLoading(false);
        }
    };

    return (
        <div className="forgot-pswd-container auth-container-main">
            <div className="wrapper-side form-container">
                <div className="form-container">
                    <div className="logo-wrapper">
                        <img src={logoSm} alt="" />
                    </div>
                    <h1 className="auth-title">Reset Password</h1>
                    <form onSubmit={handleSubmit} className="auth-form signup" >
                        <div className="form-group-dx">
                            <label style={{ marginBottom: "10px" }}><b>Mobile:</b> {location.state.mobile}</label>
                            <span className="password-label-wrapper">
                                <label htmlFor="password">Password</label>
                                <button
                                    type="button"
                                    className="toggle-password"
                                    onClick={togglePasswordVisibility}
                                >
                                    {passwordVisible ? "Hide" : "Show"}
                                </button>
                            </span>
                            <div className="password-container">
                                <input
                                    type={passwordVisible ? "text" : "password"}
                                    id="password"
                                    name="password"
                                    value={formData.password}
                                    placeholder="Enter password"
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <p className="password-hint">
                                Use 8 or more characters with a mix of letters, numbers & symbols
                            </p>
                            {formErrors.password && (
                                <p className="dx-lib input-error">{formErrors.password}</p>
                            )}
                        </div>
                        <button type="submit" className="submit-button dx-btn-primary" disabled={loading}>
                            {loading ? "Resetting Password..." : "Reset Password"}
                        </button>
                    </form>
                    <p className="account-exist-check">
                        Remember password? <Link to="/">Login</Link>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ForgotPasswordValidate;