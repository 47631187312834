import axios from "axios";
import config from "../config";

export const ReadCountriesService = async () => {
    var countries_data = [];
    axios.defaults.baseURL = config.servers["base"];
    await axios
        .get("countries/", {
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then((response) => {
            if (response.data.success){
                countries_data = response.data.data.countries;
            }
        })
        .catch((error) => {
        });

    return await countries_data;
};